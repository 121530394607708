<template>
   <div class="searchwrap">
     <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('searchArea.memberID') }}</span></div>
          <div>
             <input type="text" class="mr-5" v-model="reqData.memId" />
             <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
          </div>
        </div>
     </div>
   </div>
  <div class="main-contents-wrapper" id="top">
    <template v-if="memberDetail">
      <div class="tab-wrap">
         <button type="button" @click="onChangeType('bottomdetail')" class="tab-item" :class="{'active': type === 'bottomdetail'}">하부업체 상세</button>
         <button type="button" @click="onChangeType('gamerrate')" class="tab-item" :class="{'active': type === 'gamerrate'}">게임별 알수수료율 설정</button>
         <button type="button" @click="onChangeType('gameset')" class="tab-item" :class="{'active': type === 'gameset'}">게임별 베팅설정</button>
         <button type="button" @click="onChangeType('insurset')" class="tab-item" :class="{'active': type === 'insurset'}">보험베팅커미션 설정</button>
      </div>
      <!--div class="tab-wrap">
        <a @click="setCurrentTab('tab1')" :class="{'active': currentMenu === 'tab1'}" class="tab-item active">회사정보</a>
        <a @click="setCurrentTab('tab2')" :class="{'active': currentMenu === 'tab2'}" class="tab-item">회사통계</a>
        <a @click="setCurrentTab('tab3')" :class="{'active': currentMenu === 'tab3'}" class="tab-item">{{ $t('user.funcSet') }}</a>
        <a @click="setCurrentTab('tab4')" :class="{'active': currentMenu === 'tab4'}" class="tab-item">상위업체 리스트</a>
        <a @click="setCurrentTab('tab5')" :class="{'active': currentMenu === 'tab5'}" class="tab-item">하위업체 리스트</a>
        <a @click="setCurrentTab('tab6')" :class="{'active': currentMenu === 'tab6'}" class="tab-item">{{ $t('table.head.memo') }}</a>
        <a @click="setCurrentTab('tab7')" :class="{'active': currentMenu === 'tab7'}" class="tab-item">알 설정</a>
        <a @click="setCurrentTab('tab8')" :class="{'active': currentMenu === 'tab8'}" class="tab-item">게임별 알수수료율 설정</a>
        <a @click="setCurrentTab('tab9')" :class="{'active': currentMenu === 'tab9'}" class="tab-item">게임별 베팅설정</a>
        <a @click="setCurrentTab('tab10')" :class="{'active': currentMenu === 'tab10'}" class="tab-item">보험베팅커미션 설정</a>
      </div-->

      <div class="allwrap" v-if="type == 'bottomdetail'">
         <div>
            <article class="content_wrap all">
               <div>
                  <table>
                   <colgroup>
                      <col width="15%">
                      <col width="35%">
                      <col width="15%">
                      <col width="35%">
                   </colgroup>
                   <thead>
                    <tr>
                       <th colspan="3" class="left">회사정보</th>
                       <th class="flexEnd">
                          <div class="saveBtnWrap">
                             <button class="btn-save user-btn-bg" @click="setDetailBasic">{{ $t('button.save') }}</button>
                          </div>
                       </th>
                    </tr>
                   </thead>
                   <tbody>
                   <tr class="content">
                     <td class="title">{{ $t('table.head.id') }}</td>
                     <td>
                       <span>{{ memberDetail.memId }}</span>
                     </td>
                     <td class="title">도메인</td>
                     <td>
                       <input type="text" v-model="memberDetail.siteDomain" />
                     </td>
                   </tr>
                   <tr class="content">
                     <td class="title">{{ $t("common.credit") }}</td>
                     <td>
                       <span>{{ numberWithCommas(memberDetail.creditAmt) }}</span>
                     </td>
                     <td class="title">알 서브포인트</td>
                     <td>
                       <span>{{ numberWithCommas(memberDetail.creditSubPoint) }}</span>
                     </td>
                   </tr>
                   <tr class="content">
                     <td class="title">보유포인트</td>
                     <td>
                       <span>{{ numberWithCommas(memberDetail.totalPointAmt) }}</span>
                     </td>
                     <td class="title">하위 업체 수</td>
                     <td>
                       <span>{{ numberWithCommas(memberDetail.totalCompanyCnt) }}</span>
                       <!-- <span>-</span> -->
                     </td>
                   </tr>
                   <tr class="content">
                    <td class="title">회사등급</td>
                    <td>
                       <span>{{ $t(memberDetail.partnerType) }}</span>
                    </td>
                    <td class="title">회사명</td>
                    <td>
                       <input type="text" v-model="memberDetail.memName" />
                    </td>
                   </tr>
                   <tr class="content">
                    <td class="title">보험금</td>
                    <td>
                       <span>{{ numberWithCommas(memberDetail.insuredAmt) }}</span>
                    </td>
                    <td class="title">보험서브포인트</td>
                    <td>
                       <span>{{ numberWithCommas(memberDetail.insuredSubPoint) }}</span>
                    </td>
                   </tr>
                   <tr class="content">
                    <td class="title">보유머니</td>
                    <td>
                       <span>{{ numberWithCommas(memberDetail.totalCashAmt) }}</span>
                    </td>
                    <td class="title">총 회원 수</td>
                    <td>
                       <!-- <span>-</span> -->
                       <span>{{ numberWithCommas(memberDetail.totalMemCnt) }}</span>
                    </td>
                   </tr>
                   <tr class="content">
                    <td class="title">회원 제한수</td>
                    <td>
                       <input type="number" v-model="memberDetail.userLimit" />
                    </td>
                    <td class="title">비밀번호</td>
                    <td>
                       <input type="password" v-model="currentPassword" />
                    </td>
                   </tr>
                   <tr class="content">
                    <td class="title">하부업체 제한수</td>
                    <td>
                       <input type="number" v-model="memberDetail.companyLimit" />
                    </td>
                    <td class="title">상위업체 리스트</td>
                    <td>
                        <div class="supComp-list-wrap">
                          <template v-for="(item, idx) in partnerFlow" :key="item.upid">
                           <span :class="memberDetail.memId === item.upid ? 'compColor-target' : 'compColor' + idx">{{ item.upid }}</span>
                          </template>
                        </div>
                    </td>
                   </tr>
                   </tbody>
                  </table>

                  <div class="part-wrap">
                     <div class="part-box">
                        <table>
                          <colgroup>
                           <col width="45%">
                           <col width="55%">
                          </colgroup>
                          <thead>
                           <tr>
                              <th class="left">회사통계</th>
                              <th></th>
                           </tr>
                          </thead>
                          <tbody>
                           <tr class="content">
                             <td class="title">총 입금</td>
                             <td>
                               <span>{{ numberWithCommas(memberDetail.totalCashInAmt) }}</span>
                             </td>
                           </tr>
                           <tr class="content">
                             <td class="title">총 보험포인트</td>
                             <td>0</td>
                           </tr>
                           <tr class="content">
                             <td class="title">총 베팅금</td>
                             <td>
                               <span>{{ numberWithCommas(memberDetail.totalWlAmt) }}</span>
                             </td>
                           </tr>
                           <tr class="content">
                             <td class="title">총 출금</td>
                             <td>
                               <span>{{ numberWithCommas(memberDetail.totalCashOutAmt) }}</span>
                             </td>
                           </tr>
                           <tr class="content">
                             <td class="title">총 게임머니</td>
                             <td>
                               <span>{{ numberWithCommas(memberDetail.totalGameMoney) }}</span>
                             </td>
                           </tr>
                           <tr class="content">
                             <td class="title">총 윈루즈</td>
                             <td>
                               <span>{{ numberWithCommas(memberDetail.totalWlAmt) }}</span>
                             </td>
                           </tr>
                           </tbody>
                        </table>
                     </div>
                     <div class="part-box">
                        <table>
                         <colgroup>
                            <col width="24%">
                            <col width="26%">
                            <col width="24%">
                            <col width="26%">
                         </colgroup>
                         <thead>
                          <tr>
                             <th class="left" colspan="3">알 설정</th>
                             <th class="flexEnd">
                                <div class="saveBtnWrap">
                                   <button class="btn-save user-btn-bg" @click="setPtSettings">{{ $t('button.save') }}</button>
                                </div>
                             </th>
                          </tr>
                         </thead>
                         <tbody>
                          <tr class="content">
                            <td class="title" data-i18n="mDetail.8">알보유제한금액</td>
                            <td class="td-toggle">
                              <div class="toggle-wrap">
                                <input type="text" class="w120" maxLength="14" :value="numberWithCommas(memberDetail.maxCredit)" @input="onlyNumber" @change="setModelData" />
                              </div>
                            </td>
                           <td class="title pl-20" data-i18n="mDetail.8">알 구매 수수료율</td>
                           <td class="td-toggle">
                            <div class="toggle-wrap">
                               <input type="number" list="ptBuyRate" class="mr-5 batchRate" ref="ptRateControl" v-model="memberDetail.creditRate" /> %
                               <datalist id="ptBuyRate">
                                <template v-for="(n, index) in 100 + 1" :key="index">
                                   <option :value="index * 1">
                                     {{ index * 1 }}
                                   </option>
                                </template>
                               </datalist>
                            </div>
                           </td>
                          </tr>
                         </tbody>
                        </table>

                        <table>
                           <colgroup>
                              <col width="24%">
                              <col width="26%">
                              <col width="24%">
                              <col width="26%">
                           </colgroup>
                           <thead>
                              <tr>
                                 <th class="left" colspan="3">{{ $t('user.funcSet') }}</th>
                                 <th class="flexEnd">
                                    <div class="saveBtnWrap">
                                        <button class="btn-save user-btn-bg" @click="setSiteSettings">{{ $t('button.save') }}</button>
                                    </div>
                                 </th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr class="content">
                                <td class="title">입금 가능</td>
                                <td class="td-toggle">
                                 <div class="toggle-wrap">
                                    <input type="checkbox" class="set-switch" @click="switchToggle" v-model="siteOption.cashInYn" />
                                 </div>
                                </td>
                                <td class="title">하위보험사용</td>
                                <td class="td-toggle">
                                 <div class="toggle-wrap">
                                    <input type="checkbox" class="set-switch" @click="switchToggle" v-model="siteOption.insuredUpYn" />
                                 </div>
                                </td>
                              </tr>
                              <tr class="content">
                                <td class="title">출금 가능</td>
                                <td class="td-toggle">
                                 <div class="toggle-wrap">
                                    <input type="checkbox" class="set-switch" @click="switchToggle" v-model="siteOption.cashOutYn" />
                                 </div>
                                </td>
                                <td class="title">{{ $t('table.head.betYn') }}</td>
                                <td class="td-toggle">
                                 <div class="toggle-wrap">
                                    <input type="checkbox" class="set-switch" @click="switchToggle" v-model="siteOption.betYn" />
                                 </div>
                                </td>
                              </tr>
                              <tr class="content">
                                <td class="title">사이트 운전</td>
                                <td class="td-toggle">
                                 <div class="toggle-wrap">
                                    <input type="checkbox" class="set-switch" @click="switchToggle" v-model="siteOption.siteStatusYn" />
                                 </div>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>

               <div>
                  <div class="button-wrap">
                   <!--button class="user-btn-bg" type="button" data-link="withdraw" @click="cashInModal.active = !cashInModal.active">알 지급</!--button>
                   <button-- class="user-btn-bg" type="button" data-link="point" @click="cashOutModal.active = !cashOutModal.active">알 회수</button-->
                   <button class="user-btn-bg" type="button" data-link="withdraw" @click="insureinModal.active = !insureinModal.active">보험금 지급</button>
                   <button class="user-btn-bg" type="button" data-link="point" @click="insureoutModal.active = !insureoutModal.active">보험금 회수</button>
                  </div>

                  <table style="height:100%;">
                   <thead>
                     <tr>
                       <th colspan="2" class="left">하위업체 리스트</th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr>
                       <td colspan="2">
                          <div class="middle-wrap">
                            <div class="table-wrap">
                              <table class="mainTable">
                                <thead>
                                <tr>
                                  <th>회사명</th>
                                  <th>{{ $t('table.head.id') }}</th>
                                  <th>회원등급</th>
                                  <th>보유머니</th>
                                  <th>보유포인트</th>
                                  <th>생성일</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="subCompanyList.length !== 0">
                                  <tr v-for="item in subCompanyList" :key="item.memid">
                                    <td>
                                      <button type="button" class="btn-link fc-green" @click="detailOpen('company', item.memid)">{{ item.memName }}</button>
                                    </td>
                                    <td>
                                      <button type="button" class="btn-link" @click="detailOpen('company', item.memid)">{{ item.memid }}</button>
                                    </td>
                                    <td>{{ $t(item.partnerType) }}</td>
                                    <td>{{ numberWithCommas(item.cashAmt) }}</td>
                                    <td>{{ numberWithCommas(item.pointAmt) }}</td>
                                    <td>{{ replaceDateT(item.regDt) }}</td>
                                  </tr>
                                </template>
                                <template v-else>
                                  <tr>
                                    <td colspan="6">{{ $t('txt.noData') }}</td>
                                  </tr>
                                </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                       </td>
                     </tr>
                   </tbody>
                  </table>

                  <table style="height:100%;">
                   <thead>
                     <tr>
                       <th class="left">{{ $t('table.head.memo') }}</th>
                       <th class="flexEnd">
                         <div class="saveBtnWrap">
                           <button class="btn-save user-btn-bg" @click="userMemoUpdate">{{ $t('button.save') }}</button>
                         </div>
                       </th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr>
                       <td colspan="2">
                         <textarea id="accessDesc" name="accessDesc" v-model="memberDetail.memo"></textarea>
                       </td>
                     </tr>
                   </tbody>
                  </table>
               </div>
              <!--div class="user-wrapper-outer mb50" id="tab4">
                <div class="wrapper-title">
                  <span>상위업체 리스트</span>
                </div>
                <div class="middle-wrap">
                  <div class="supComp-list-wrap">
                    <template v-for="(item, idx) in partnerFlow" :key="item.upid">
                      <span :class="memberDetail.memId === item.upid ? 'compColor-target' : 'compColor' + idx">{{ item.upid }}</span>
                    </template>
                  </div>
                </div>
              </div-->

              <!--a href="#top" class="scrolltTop">맨 위로</a-->
            </article>
         </div>
      </div>
      <div v-if="type == 'gamerrate'">
         <div class="user-wrapper-outer mb50" id="tab8">
           <div class="game-wrap">
              <div class="wrapper-title">
                <div class="subWrap fx vt-c gap-10">
                  <span>게임별 알수수료율 설정</span>
                  <div>
                    <input type="number" list="ptRate" class="mr-5 batchRate" ref="ptRateControl" />
                    <datalist id="ptRate">
                      <template v-for="(n, index) in 200 + 1" :key="index">
                        <option :value="(index * 0.5).toFixed(1)">
                          {{ (index * 0.5).toFixed(1) }}
                        </option>
                      </template>
                    </datalist>
                    <button type="button" class="btn-layout btn-blue mr-5" @click="ptRateAllChange">일괄적용</button>
                    <button type="button" class="btn-layout btn-grey" title="새로고침" @click="refreshData">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </div>
                <div>
                  <button class="btn-layout btn-red mr-5" type="button" @click="openVenderRateSetting">벤더사별 게임 수수료</button>
                  <button class="btn-layout btn-red mr-5" type="button" @click="openRateSettings">{{ $t('common.detailSet') }}</button>
                  <button class="btn-save user-btn-bg" @click="setPtRates">{{ $t('button.save') }}</button>
                </div>
              </div>
              <div class="rate-item-container">
                <template v-for="(item, n) in memberDetail.memberCommissionRate" :key="item.code">
                  <div class="rate-item-wrap">
                    <div class="item-title">
                      <span>{{ item.codeName }}</span>
                    </div>
                    <div class="item-content">
                      <div class="info-box">{{ Number(item.pointRate).toFixed(1) }} %</div>
                    </div>
                  </div>
                  <div v-if="(n + 1) % 4 !== 0" class="item-line"></div>
                </template>
              </div>
           </div>
         </div>
      </div>
      <div v-if="type == 'gameset'">
         <div class="user-wrapper-outer mb50" id="tab9">
           <div class="game-wrap">
              <div class="wrapper-title">
                <div class="subWrap fx vt-c gap-10">
                  <span>게임별 베팅설정</span>
                  <div>
                    <button type="button" class="btn-layout btn-blue mr-5" @click="rateAllChange('Y')">전체 ON</button>
                    <button type="button" class="btn-layout btn-red mr-5" @click="rateAllChange('N')">전체 OFF</button>
                    <button type="button" class="btn-layout btn-grey" title="새로고침" @click="refreshData">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </div>
                <div>
                  <button class="btn-layout btn-red mr-5" type="button" @click="openRateToggle">{{ $t('common.detailSet') }}</button>
                  <button class="btn-save user-btn-bg" @click="setBetYns">{{ $t('button.save') }}</button>
                </div>
              </div>
              <div class="rate-item-container">
                <template v-for="(item, n) in memberDetail.BI_SubPointRate" :key="item.vendorCode">
                  <div class="rate-item-wrap">
                    <div class="item-title">
                      <span>{{ item.vendorName }}</span>
                    </div>
                    <div class="item-content">
                      <div class="toggle-wrap">
                        <span class="toggle_text" :class="item.compBetYn === 'Y' ? 'on' : 'off'"></span>
                      </div>
                    </div>
                  </div>
                  <div v-if="(n + 1) % 4 !== 0" class="item-line"></div>
                </template>
              </div>
           </div>
         </div>
      </div>
      <div v-if="type == 'insurset'">
         <div class="user-wrapper-outer mb50" id="tab10">
           <div class="game-wrap">
              <div class="wrapper-title">
                <div class="subWrap fx vt-c gap-10">
                  <span>보험베팅커미션 설정</span>
                  <div>
                    <input type="number" list="biRate" class="mr-5 batchRate" ref="biRateControl" />
                    <datalist id="biRate">
                      <template v-for="(n, index) in 10000 + 1" :key="n">
                        <option :value="(index * 0.01).toFixed(2)">
                          {{ (index * 0.01).toFixed(2) }}
                        </option>
                      </template>
                    </datalist>
                    <button type="button" class="btn-layout btn-blue mr-5" @click="biRateAllChange">일괄적용</button>
                    <button type="button" class="btn-layout btn-grey" title="새로고침" @click="refreshData">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </div>
                <div>
                  <button class="btn-layout btn-red mr-5" type="button" @click="openBiRateSetting">{{ $t('common.detailSet') }}</button>
                  <button class="btn-save user-btn-bg" @click="setBiBetRates">{{ $t('button.save') }}</button>
                </div>
              </div>
              <div class="rate-item-container">
                <template v-for="(item, n) in memberDetail.BI_SubPointRate" :key="item.vendorCode">
                  <div class="rate-item-wrap">
                    <div class="item-title">
                      <span>{{ item.vendorName }}</span>
                    </div>
                    <div class="item-content">
                      <div class="info-box">{{ item.biSubPointRate }} %</div>
                    </div>
                  </div>
                  <div v-if="(n + 1) % 4 !== 0" class="item-line"></div>
                </template>
              </div>
           </div>
         </div>
      </div>

      <div class="modal-wrapper" v-if="cashInModal.active">
        <div class="modal-wrap2">
           <div class="sub-title2">
             <h2>알 지급</h2>
             <h4>{{ getDomain () }}</h4>
             <a class="btn" @click="inCashModalClose"><i class="fas fa-times"></i></a>
           </div>
           <div class="modal-content2">
             <div class="onlyNum">
                <input type="number" v-model="cashInModal.reqData.creditAmt" />
             </div>
           </div>
           <div class="modal-btns">
             <a @click="cashApply('in', memberDetail.siteId)">{{ $t('searchArea.payment') }}</a>
           </div>
        </div>
      </div>
      <div class="modal-wrapper" v-if="cashOutModal.active">
        <div class="modal-wrap2">
           <div class="sub-title2">
             <h2>알 회수</h2>
             <h4>{{ getDomain () }}</h4>
             <a class="btn" @click="outCashModalClose"><i class="fas fa-times"></i></a>
           </div>
           <div class="modal-content2">
             <div class="onlyNum">
                <input type="number" v-model="cashOutModal.reqData.creditAmt" />
             </div>
           </div>
           <div class="modal-btns">
             <a @click="cashApply('out', memberDetail.siteId)">{{ $t('searchArea.retrieval') }}</a>
           </div>
        </div>
      </div>
      <div class="modal-wrapper" v-if="insureinModal.active">
        <div class="modal-wrap2">
           <div class="sub-title2">
             <h2>보험금 지급</h2>
             <h4>{{ getDomain () }}</h4>
             <a class="btn" @click="onOpenInsureInModal"><i class="fas fa-times"></i></a>
           </div>
           <div class="modal-content2">
             <div class="onlyNum">
                <input type="text" v-model="insureinModal.amt"/>
             </div>
           </div>
           <div class="modal-btns">
             <a @click="onSubmitInsureIn">{{ $t('searchArea.payment') }}</a>
           </div>
        </div>
      </div>
      <div class="modal-wrapper" v-if="insureoutModal.active">
        <div class="modal-wrap2">
           <div class="sub-title2">
             <h2>보험금 회수</h2>
             <h4>{{ getDomain () }}</h4>
             <a class="btn" @click="onOpenInsureOutModal"><i class="fas fa-times"></i></a>
           </div>
           <div class="modal-content2">
             <div class="onlyNum">
                <input type="text" v-model="insureoutModal.amt"/>
             </div>
           </div>
           <div class="modal-btns">
             <a @click="onSubmitInsureOut">{{ $t('searchArea.retrieval') }}</a>
           </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import lodash from 'lodash'
import { getCode } from '@/api/common'
// import { companyDetail, companyTree, siteOption, siteOptionSave } from "@/api/company";
import {
  companyDetail,
  companyTreeList,
  siteOption,
  siteOptionSave,
  memberRateUpdate,
  cashIn,
  cashOut
} from '@/api/member'
import {
  companyModify,
  compSiteSettingModify,
  compBasicInfoModify,
  compPtSettingModify,
  compGamesBiSubRateModify,
  compGamesBetModify,
  compGamesRateModify,
  companyBiSubPointRates,
  setPTCreditIn,
  setPTCreditOut
} from '@/api/company'
import { GAME_INFO_LIST } from '@/libs/constants.js'
import subTitle from '@/components/main/PageSubTitle'
import { deleteCommas } from '../../../../libs/utils'
import { insuredApply, insuredIn, insuredOut } from '@/api/insured'
import { creditIn, creditOut } from '@/api/partner'
import { mapState } from 'vuex'

export default {
  name: 'companyDetail',
  components: {
    subTitle
  },
  computed: {
    ...mapState([
      'siteOption'
    ])
  },
  data () {
    return {
      type: 'bottomdetail',
      currentPassword: '',
      currentMenu: 'tab1',
      reqData: {
        siteId: this.$route.query.id,
        memId: this.$route.query.id
      },
      subCompanyList: [],
      cashInModal: {
        active: false,
        text: '입금',
        reqData: {
          siteId: '',
          creditAmt: 0
        }
      },
      detailInfo: {},
      siteOption: {},
      cashOutModal: {
        active: false,
        text: '출금',
        reqData: {
          siteId: '',
          creditAmt: 0
        }
      },
      insureinModal: {
        active: false,
        amt: 0
      },
      insureoutModal: {
        active: false,
        amt: 0
      },
      creditInModal: {
        active: false,
        text: '크레딧 지급',
        reqData: {
          siteId: '',
          bonusRollingYn: 'N',
          outAmt: 0,
          memo: '',
          type: 'N'
        }
      },
      creditOutModal: {
        active: false,
        text: '크레딧 회수',
        reqData: {
          siteId: '',
          bonusRollingYn: 'N',
          outAmt: 0,
          memo: '',
          type: 'N'
        }
      },
      rateList: [],
      userRate: [],
      gameList: [],
      originRate: [],
      rateGroup: {
        etc1: '',
        etc2: '',
        etc3: ''
      },
      memberDetail: null
    }
  },
  watch: {
    'insureinModal.amt' () {
      const parts = this.insureinModal.amt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      // const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.insureinModal.amt = n
    },
    'insureoutModal.amt' () {
      const parts = this.insureoutModal.amt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      // const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.insureoutModal.amt = n
    }
  },
  methods: {
    onOpenInsureInModal () {
      this.insureinModal.active = !this.insureinModal.active
      this.insureinModal.amt = '0'
    },
    onOpenInsureOutModal () {
      this.insureoutModal.active = !this.insureoutModal.active
      this.insureoutModal.amt = '0'
    },
    onSubmitInsureIn () {
      if (this.insureinModal.amt && this.insureinModal.amt !== '0') {
        const amt = Number(this.insureinModal.amt.replace(/,/g, ''))
        if (confirm('보험금을 지급하시겠습니까?')) {
          const params = {
            siteId: this.memberDetail.memId,
            insuredAmt: amt,
            memo: ''
          }

          insuredIn(params).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              alert('보험금이 지급되었습니다.')
              location.reload()
            }
          })
        }
      } else {
        alert('보험금액을 입력해주세요.')
      }
    },
    onSubmitInsureOut () {
      if (this.insureoutModal.amt && this.insureoutModal.amt !== '0') {
        const amt = Number(this.insureoutModal.amt.replace(/,/g, ''))
        if (confirm('보험금을 회수하시겠습니까?')) {
          const params = {
            siteId: this.memberDetail.memId,
            insuredAmt: amt,
            memo: ''
          }

          insuredOut(params).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              alert('보험금이 회수되었습니다.')
              location.reload()
            }
          })
        }
      } else {
        alert('보험금액을 입력해주세요.')
      }
    },
    getDomain () {
      return location.host
    },
    onChangeType (type) {
      this.type = type
    },
    setCurrentTab (type) {
      this.currentMenu = type
      document.querySelector('#' + type).scrollIntoView({ behavior: 'smooth' })
    },
    async pageSeach () {
      if (this.reqData.memId) {
        this.reqData.siteId = this.reqData.memId
        this.memberDetail = null
        await this.getCompanyDetail()
        await this.getCompanyTree()
        await this.getsiteOption()
        this.combineUserRate()
      } else {
        this.memberDetail = null
      }
    },
    pageMoveLocal (name) {
      const params = {

      }
      this.pageMove()
    },
    async setSiteSettings () {
      const option = lodash.cloneDeep(this.siteOption)
      for (const key in option) {
        if (typeof option[key] === 'boolean') {
          if (option[key]) {
            option[key] = 'Y'
          } else {
            option[key] = 'N'
          }
        }
      }
      const model = {
        siteId: this.memberDetail.memId,
        siteOption: {
          cashInYn: option.cashInYn,
          cashOutYn: option.cashOutYn,
          betYn: option.betYn,
          siteStatusYn: option.siteStatusYn,
          insuredUpYn: option.insuredUpYn
        }
      }
      const res = await compSiteSettingModify(model)
      if (res.resultCode === '0') {
        await this.getsiteOption()
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    async setDetailBasic () {
      const model = {
        siteId: this.memberDetail.memId,
        siteName: this.memberDetail.memName,
        siteDomain: this.memberDetail.siteDomain
      }
      if (this.currentPassword !== this.memberDetail.memPass) {
        model.password = this.currentPassword
      }
      const res = await compBasicInfoModify(model)
      if (res.resultCode === '0') {
        alert('설정완료')
        await this.refreshData()
      } else {
        alert('설정실패')
      }
    },
    setModelData (e) {
      const target = e.target
      const value = e.target.value
      this.memberDetail.maxCredit = deleteCommas(value)
      console.log(this.memberDetail.maxCredit)
    },
    async setPtSettings () {
      const model = {
        siteId: this.memberDetail.memId,
        maxCredit: this.memberDetail.maxCredit / Number(this.siteOption.cashDecimal),
        creditRate: this.memberDetail.creditRate
      }
      const res = await compPtSettingModify(model)
      console.log(res)
      if (res.resultCode === '0') {
        alert('설정완료')
        await this.refreshData()
      } else {
        alert('설정실패')
      }
    },
    async setBetYns () {
      const req = {
        siteId: this.memberDetail.memId,
        biRateList: []
      }
      for (const item of this.memberDetail.BI_SubPointRate) {
        const model = {
          gameType: item.gameType,
          vendorCode: item.vendorCode,
          compBetYn: item.compBetYn
        }
        req.biRateList.push(model)
      }
      const res = await compGamesBetModify(req)
      if (res.resultCode === '0') {
        alert('설정완료')
        await this.refreshData()
      } else {
        alert('설정실패')
      }
    },
    rateAllChange (type) {
      const rateInfo = this.memberDetail.BI_SubPointRate
      for (const item of rateInfo) {
        item.compBetYn = type
      }
    },
    biRateAllChange () {
      const target = this.$refs.biRateControl
      let value = target.value
      console.log(value)
      if (value > 100) {
        value = 100
      } else if (value < 0) {
        value = 0
      }
      const ptRates = this.memberDetail.BI_SubPointRate
      for (const item of ptRates) {
        item.biSubPointRate = value
      }
    },
    async setBiBetRates () {
      console.log(this.memberDetail)
      const req = {
        siteId: this.memberDetail.memId,
        biRateList: []
      }
      for (const item of this.memberDetail.BI_SubPointRate) {
        const model = {
          gameType: item.gameType,
          vendorCode: item.vendorCode,
          biSubPointRate: item.biSubPointRate
        }
        req.biRateList.push(model)
      }
      const res = await compGamesBiSubRateModify(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('설정완료')
        await this.refreshData()
      } else {
        alert('설정실패')
      }
    },
    async setPtRates () {
      console.log(this.memberDetail)
      const req = {
        siteId: this.memberDetail.memId,
        rateList: []
      }
      for (const item of this.memberDetail.memberCommissionRate) {
        const model = {
          gameType: item.gameType,
          pointRate: item.pointRate
        }
        req.rateList.push(model)
      }
      const res = await compGamesRateModify(req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('설정완료')
        await this.refreshData()
      } else {
        alert('설정실패')
      }
    },
    async refreshData () {
      try {
        await this.setGameList()
        this.setRateListDefault()
        await this.getCompanyDetail()
        await this.getCompanyTree()
        await this.getsiteOption()
        this.combineUserRate()
      } catch (e) {
        console.error(e)
      } finally {
        // this.scontentSetting();
      }
    },
    ptRateAllChange () {
      const target = this.$refs.ptRateControl
      let value = target.value
      console.log(value)
      if (value > 100) {
        // console.log("value=1000");
        value = 100
      } else if (value < 0) {
        // console.log("value=0");
        value = 0
      }
      const ptRates = this.memberDetail.memberCommissionRate
      for (const item of ptRates) {
        const itemMaxRate = item.maxRate
        console.log(item.maxRate)
        console.log(value)
        console.log(itemMaxRate < value)
        // const itemRate = item.pointRate;
        if (Number(itemMaxRate) < Number(value)) {
          item.pointRate = itemMaxRate
        } else {
          item.pointRate = value
        }
      }
    },
    openVenderRateSetting () {
      const name = 'venderRateSetting'
      const targetId = this.memberDetail.siteId

      const size = {
        width: 1200,
        height: 840
      }
      const position = {
        top: 0,
        left: 150
      }
      const routeData = this.$router.resolve({ name, params: { id: targetId } })
      window.open(routeData.href, 'venderRateSetting', `top=${position.top},left=${position.left},scrollbars=0,resizable=0,width=${size.width},height=${size.height},status=no`)
    },
    openRateSettings () {
      const name = 'companyBetSetting'
      const targetId = this.memberDetail.siteId

      const size = {
        width: 585,
        height: 840
      }
      const position = {
        top: 0,
        left: 150
      }
      const routeData = this.$router.resolve({ name, params: { id: targetId } })
      window.open(routeData.href, 'rateSetting', `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    openRateToggle () {
      const name = 'rateSettingsToggle'
      const targetId = this.memberDetail.siteId

      const size = {
        width: 585,
        height: 840
      }
      const position = {
        top: 0,
        left: 150
      }
      const routeData = this.$router.resolve({ name, params: { id: targetId } })
      window.open(routeData.href, 'rateSetting', `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    openBiRateSetting () {
      const name = 'biRateSettings'
      const targetId = this.memberDetail.siteId

      const size = {
        width: 585,
        height: 840
      }
      const position = {
        top: 0,
        left: 150
      }
      const routeData = this.$router.resolve({ name, params: { id: targetId } })
      window.open(routeData.href, 'rateSetting', `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    async setBiSubPointRate () {
      const model = {
        siteId: this.memberDetail.siteId,
        biRateList: lodash.cloneDeep(this.memberDetail.BI_SubPointRate)
      }
      const res = await companyBiSubPointRates(model)
      console.log(res)
    },
    async _memLogout (siteId) {
      const reqData = {
        siteId
      }
      const res = await memLogout(reqData)
      if (res.resultCode === '0') {
        alert('로그아웃 처리 완료')
      } else {
        alert('로그아웃 처리 실패')
      }
    },
    async cashApply (type, siteId) {
      let res = ''
      if (type === 'in') {
        this.cashInModal.reqData.siteId = siteId
        console.log(this.cashInModal.reqData)
        this.cashInModal.reqData.creditAmt = this.cashInModal.reqData.creditAmt / Number(this.siteOption.cashDecimal)
        res = await setPTCreditIn(this.cashInModal.reqData)
        console.log(res)
        if (res.resultCode === '0') {
          alert('지급완료')
          this.getCompanyDetail()
          this.cashInModal.active = false
          this.cashInModal.reqData.creditAmt = 0
        } else if (res.resultCode === 'P006') {
          alert('포인트지급,회수 처리는 1분에 1건씩 처리됩니다. 잠시후 다시 시도해주세요')
        } else {
          alert('지급실패, 다시 시도해주세요.')
        }
      } else if (type === 'out') {
        this.cashOutModal.reqData.siteId = siteId
        this.cashOutModal.reqData.creditAmt = this.cashOutModal.reqData.creditAmt / Number(this.siteOption.cashDecimal)
        res = await setPTCreditOut(this.cashOutModal.reqData)
        if (res.resultCode === '0') {
          alert('회수완료')
          this.getCompanyDetail()
          this.cashOutModal.active = false
          this.cashOutModal.reqData.creditAmt = 0
        } else {
          alert('회수실패, 다시 시도해주세요.')
        }
      }
    },
    async userInfoUpdate () {
      const reqData = {
        siteId: this.memberDetail.siteId,
        memNick: this.memberDetail.memNick,
        memName: this.memberDetail.memName,
        memLevel: this.memberDetail.memLevel
      }
      const res = await companyModify(reqData)
      if (res.resultCode === '0') {
        alert('회원정보수정 완료')
      } else {
        alert('회원정보 변경실패, 다시 시도해주세요.')
      }
    },
    async userMemoUpdate () {
      const reqData = {
        siteId: this.memberDetail.siteId,
        memo: this.memberDetail.memo
      }
      const res = await companyModify(reqData)
      if (res.resultCode === '0') {
        alert('상담메모수정 완료')
      } else {
        alert('상담메모수정실패, 다시 시도해주세요.')
      }
    },
    async userGLevelUpdate () {
      const reqData = {
        siteId: this.memberDetail.siteId,
        sportsLevel: this.memberDetail.sportsLevel,
        casinoLevel: this.memberDetail.casinoLevel,
        slotLevel: this.memberDetail.slotLevel,
        mgLevel: this.memberDetail.mgLevel
      }
      const res = await memberRateUpdate(reqData)
      if (res.resultCode === '0') {
        alert('게임레벨 수정 완료')
      } else if (res.resultCode === 'U031') {
        alert(res.resultMessage)
      } else {
        alert('게임레벨 수정 실패, 다시 시도해주세요.')
      }
    },
    async rateUpdate () {
      const reqData = {
        siteId: this.memberDetail.siteId,
        memId: this.memberDetail.memId,
        rateList: []
      }
      // console.log(this.rateList);
      for (const item of this.rateList) {
        const _item = lodash.cloneDeep(item)
        if (_item.useYn) {
          _item.useYn = 'Y'
        } else {
          _item.useYn = 'N'
        }
        if (_item.betYn) {
          _item.betYn = 'Y'
        } else {
          _item.betYn = 'N'
        }
        const rateModel = {
          gameType: _item.gameType,
          pointRate: _item.pointRate,
          useYn: _item.useYn,
          betYn: _item.betYn
        }
        reqData.rateList.push(rateModel)
      }
      // console.log(reqData.rateList);
      const res = await memberRateUpdate(reqData)
      console.log(reqData)
      console.log(res)
      if (res.resultCode === '0') {
        alert('베팅설정 완료')
        // location.reload();
      } else if (res.resultCode === 'U031') {
        alert(res.resultMessage)
      } else {
        alert('베팅설정 실패, 다시 시도해주세요.')
      }
    },
    async userSettingUpdate () {
      const reqData = {
        siteId: this.memberDetail.siteId,
        accInfoRequestYn: this.memberDetail.accInfoRequestYn,
        betYn: this.memberDetail.betYn,
        siteStatusYn: this.memberDetail.siteStatusYn,
        insuredDownYn: this.memberDetail.insuredDownYn
      }
      for (const item in reqData) {
        if (item !== 'siteId') {
          if (reqData[item]) {
            reqData[item] = 'Y'
          } else {
            reqData[item] = 'N'
          }
        }
      }
      const res = await companyModify(reqData)
      if (res.resultCode === '0') {
        alert('기능설정 완료')
      } else {
        alert('기능설정 실패, 다시 시도해주세요.')
      }
    },
    parentLinkMove (linkTarget, siteId) {
      console.log('move')
      if (linkTarget === '/reports/totalList') {
        window.opener.location.href = `${linkTarget}?id=${siteId}`
      } else {
        window.opener.location.href = `/user${linkTarget}?id=${siteId}`
      }
    },
    async siteOptionSave () {
      const option = lodash.cloneDeep(this.siteOption)
      for (const key in option) {
        if (typeof option[key] === 'boolean') {
          if (option[key]) {
            option[key] = 'Y'
          } else {
            option[key] = 'N'
          }
        }
      }
      const res = await siteOptionSave(option)
      console.log(res)
      if (res.resultCode === '0') {
        await this.getsiteOption()
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    async getCompanyTree () {
      const reqData = {
        targetId: this.reqData.memId,
        searchType: 'partner'
      }
      const res = await companyTreeList(reqData)
      // console.log(res);
      if (res.resultCode !== '0') {
        return
      }
      const list = res.data.list
      if (list) {
        this.memberDetail.subCompanyCnt = list.length
      } else {
        this.memberDetail.subCompanyCnt = 0
      }
      this.subCompanyList = list
    },
    async getsiteOption () {
      const req = {
        siteId: this.memberDetail.memId
      }
      const res = await siteOption(req)
      const option = res.data.option
      for (const key in option) {
        if (key.indexOf('Yn') > -1) {
          if (option[key] === 'Y') {
            option[key] = true
          } else {
            option[key] = false
          }
        }
      }
      this.siteOption = option
    },
    switchToggle (event) {
      const toggle = event.target
      const text = toggle.previousElementSibling
      // console.log(toggle.checked)
      if (text) {
        if (toggle.checked) {
          text.classList.remove('off')
          text.classList.add('on')
        } else {
          text.classList.remove('on')
          text.classList.add('off')
        }
      }
    },
    inCashModalClose () {
      this.cashInModal.active = !this.cashInModal.active
    },
    outCashModalClose () {
      this.cashOutModal.active = !this.cashOutModal.active
    },
    insureinModalClose () {
      this.insureinModal.active = !this.insureinModal.active
    },
    insureoutModalClose () {
      this.insureoutModal.active = !this.insureoutModal.active
    },
    modalControl (siteId) {
      this.getCompanyDetail(siteId)
      this.modal.target = siteId
      this.modal.active = !this.modal.active
    },
    async getCompanyDetail () {
      console.log(this.reqData)
      const res = await companyDetail(this.reqData)
      if (res.resultCode !== '0') {
        this.emitter.emit('Loading', false)
        return false
      }
      if (res.data.memberDetail) {
        this.memberDetail = res.data.memberDetail
      }
      this.currentPassword = JSON.parse(JSON.stringify(this.memberDetail.memPass))
      this.memberFlow = res.data.memberFlow
      this.partnerFlow = this.jsonSort(res.data.partnerFlow, 'topOrder', 'desc')
      // console.log(this.partnerFlow)
      if (this.memberDetail.lastLoginDt) {
        this.memberDetail.lastLoginDt = this.replaceDateT(this.memberDetail.lastLoginDt)
      }
      this.rateGroup.etc1
      for (const item in this.memberDetail) {
        if (item.indexOf('Yn') !== -1 && item !== 'memberCommissionRate') {
          if (this.memberDetail[item] === 'Y') {
            this.memberDetail[item] = true
          } else {
            this.memberDetail[item] = false
          }
        }
      }
    },
    getUserRate () {
      return memberRate(this.reqData)
    },
    getGameCode () {
      return getCode('kplay')
    },
    setRateLoop (maxRate) {
      return maxRate / 0.05
    },
    getMemLevel () {
      this.memberDetail
    },
    async setMemStatus () {
      const reqData = {
        siteId: this.memberDetail.siteId,
        memStatus: this.memberDetail.memStatus
      }
      try {
        const res = await memStatusChange(reqData)
        console.log(res)
        if (res.resultCode !== '0') {
          console.log(res.resultCode)
          alert('회원상태 변경에 실패했습니다. 다시 시도해주세요.')
        } else {
          alert('회원상태 변경완료')
        }
      } catch (e) {
        console.log(e)
        alert('회원상태 변경에 실패했습니다. 다시 시도해주세요.')
      }
    },
    // setRateList() {
    //   this.setRateListDefault();
    //   this.combineUserRate();
    // },
    setRateListDefault () {
      // console.log(this.gameListDefault);
      for (const loop of this.gameListDefault) {
        for (const item of loop.child) {
          // console.log(item.groupCode);
          const model = lodash.cloneDeep(this.rateModel)
          model.gameType = item.code
          model.gameName = item.codeName
          model.groupCode = item.groupCode
          model.loop = Number(model.maxPointRate) / this.rateIncrease
          this.rateList.push(model)
        }
      }
      // console.log(this.rateList);
      this.originRate = lodash.cloneDeep(this.rateList)
    },
    combineUserRate () {
      const rateList = lodash.cloneDeep(this.rateList)
      // console.log(rateList)
      // console.log(this.rateList)
      // console.log(this.memberDetail)
      for (const loop of this.memberDetail.memberCommissionRate) {
        rateList.forEach((target, index, arr) => {
          if (target.gameType === loop.gameType) {
            target.pointRate = loop.pointRate
            target.maxRate = loop.maxRate
            target.maxPointRate = loop.maxPointRate
            target.loop = Math.round(Number(target.maxRate) / this.rateIncrease)
            if (loop.betYn === 'Y') {
              target.betYn = true
            } else {
              target.betYn = false
            }
            if (loop.useYn === 'Y') {
              target.useYn = true
            } else {
              target.useYn = false
            }
            return false
          }
        })
      }
      this.originRate = rateList
      this.rateList = rateList
      // console.log(this.rateList)
    },
    scontentSetting () {
      const scontent = document.querySelectorAll('.s-content-wrap')
      const parent = scontent[0].parentElement
      const title = scontent[0].previousElementSibling
      const pHeight = parent.clientHeight
      const tHeight = title.offsetHeight
      console.log()
      const targetHeight = pHeight - (tHeight + 5)
      console.log(targetHeight)
      this.scontentHeight = targetHeight
      // console.log(scontent)
    }
  },
  async created () {
    try {
      this.emitter.emit('Loading', true)
      await this.setGameList()
      this.setRateListDefault()
      await this.getCompanyDetail()
      await this.getCompanyTree()
      await this.getsiteOption()
      this.combineUserRate()
      this.emitter.emit('Loading', false)
    } catch (e) {
      console.error(e)
    } finally {
      // this.scontentSetting();
    }
  }
}
</script>

<style scoped>
.left {text-align: left !important;}
.allwrap {max-width: 1600px;}
.allwrap > div {display: flex;gap: 20px;}
.allwrap > .wrapper.fx-col {width: 100%;}
.allwrap .user-wrapper-outer > .middle-wrap > table {width: auto;}
.content_wrap {
   display: flex;
   flex-direction: column;
   gap: 20px;
   box-sizing: border-box;
   width: calc(50% - 20px);
   padding: 13px;
   background-color: #fff;
   box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
   border: solid 0.5px #b1b1b1;
}
.content_wrap.all {width: 100%;flex-direction: row;}
.content_wrap.all > div {width: 50%;display: flex;flex-direction: column;gap: 20px;}
.part-wrap {display: flex;flex-wrap: wrap;gap: 20px;}
.part-box {width: calc(32% - 20px);}
.part-box:last-child {width: 68%;display: flex;flex-direction: column;flex-wrap: wrap;gap: 23px;}

.content_wrap.w100w{
  width: 100%;
}
.content_wrap.w50w{
  width: 50%;
}
.content_wrap table {
  font-size: 12px;
  color: #585963;
  width: 100%;
}
.content_wrap table select {
  border: 1px solid #b1b1b1;
}
.content_wrap table thead{
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
  border: solid 1px #b1b1b1;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #3d3d3d;
}
.content_wrap table th {
  padding: 5px 14px;
  text-align: center;
  box-sizing: border-box;
}
.content_wrap table thead th.flexEnd{
  display: flex;
  justify-content: flex-end;
}
.content_wrap table thead th:nth-child(2) .saveBtnWrap {
  display: flex;
  gap: 10px;
}
.saveBtnWrap .user-btn-bg {
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 4px;
}
.user-btn-bg.chasnge-partner {
  color: #fff;
  background: #82a6a4;
  border: 1px solid #82a6a4;
}
.user-btn-bg.chasnge-partner:hover {
  color: #fff;
  background: #82a6a4;
}
.content_wrap table td {
  padding: 8px 14px;
  border: 1px solid #b1b1b1;
  background-color: #fff;
}
textarea {
  width: 100%;
  min-height: 100%;
  resize: none;
  box-sizing: border-box;
  font-size: 15px;
}
.fx {display: flex !important;}
.mb50 {margin-bottom: 20px;}
.scrolltTop {display: flex;align-items: center;justify-content: center;background: #000;color: #fff;width: 80px;height: 45px;float: right;}
.tab-item.active {background: #eb7a3f;}
.tab-item:hover { background: #eb7a3f; color: #fff;}
.active .btn-t-inner {
  display: none;
}
.fstColumn.userList .levelColor {
  display: block;
}

.box-hideOpen .levelColor {
  background: rgb(255, 218, 38);
  width: 8px;
}
.seletAndDelete {
  display: flex;
  align-items: center;
}
.msg-all-sel {
  margin-right: 4px !important;
  height: 30px;
  box-sizing: border-box;
}
#siteId {
  margin-right: 4px;
}
.btn-state-change.cancle {
  margin-right: 4px;
}
.user-detail-popup {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  width: fit-content;
  margin: 0;
  box-sizing: border-box;
  background: #353535;
}
.user-detail-popup select {
  font-size: 1em;
}
.main-contents-wrapper > div {margin: 0 0 30px;}
.mainTable th {background: #a4a4a4 !important;}
.wrapper-title > .subWrap > span {font-size: initial;}
.user-memo-wrap textarea {border: 1px solid #e1e0e0;}
.user-memo-wrap .content {margin: 20px 22px;}
.user-wrapper-outer > .middle-wrap {display: block;}
.user-wrapper-outer > .middle-wrap .content > .title {width: 250px;color: #353535;}
.wrapper-title {width: auto;height: 45px;border-bottom: 1px solid #d5d5d5;background: #f9f9f9;padding: 0 22px;}
.wrapper-title > span {font-size: 14px;}
.wrapper-title .btn-save {
  padding: 0 12px;
}
.button-wrap {
   display: flex;
     gap: 6px;
}
.button-wrap > button {
   flex: 0 1 25%;
   height: 28px;
   box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
   box-sizing: border-box;
   border: solid 1px #616161;
   background-color: #ffebeb;
   color: #3d3d3d;
   border-radius: 4px;
   font-size: 12px;
}
.userInfo-select {
  width: 285px;
  border: 1px solid #e1e0e0;
  font-weight: 500;
  padding: 2px 5px;
  height: 27px;
  width: 100%;
}
.s-content > .content {
  display: flex;
  height: calc(56px - 26px);
}
.onlyNum {
    position: relative;
    max-width: 190px;
    height: 30px;
    margin: 20px auto;
}
.modal-item {
  width: 500px;
  margin: 0 auto;
  position: relative;
  top: 20%;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 8px solid #6f7172;
  overflow: hidden;
}
.modal-close {top: -10px;right: 10px;color: #e1534e;}
.sub-title {border-bottom: 2px solid #e1534e;}
.modal-item .fx-item-auto {text-align: center;margin: 20px 0;}
.modal-item .fx-item-auto input {width: 200px;color: #a4a4a4;}
.btn-wrap {margin-bottom: 25px;}
.btn-wrap button {width: 200px;height: 35px;font-size: 18px;}
.table-wrap {
  width: 100%;
  overflow-y: scroll;
  max-height: 120px;
}
.mainTable {
  font-size: 12px !important;
}
.mainTable th {
  height: 35px;
  /* background: var(--main); */
  background: #353535;
  color: #fff;
  vertical-align: middle;
}

.supComp-list-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
span[class^="compColor"] {
  font-weight: bold;
  font-size: 14px;
}
span[class^="compColor"]:after {
  content: "▶";
  display: inline;
  margin-left: 10px;
  color: #a4a4a4;
}
span[class^="compColor"]:last-child:after {
  content: "";
}
.compColor-target {
  color: red;
}
.s-content-wrap {
  /* max-height: 130px; */
}
.s-content {
  flex-basis: 74px;
  max-width: 74px;
  min-height: 30px;
  margin: 5px;
  border: 1px solid #afafaf;
}
.s-content > .title {
  padding: 5px 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-wrapper-outer {
  display: flex;
  flex-direction: column;
  width: 100%;

box-sizing: border-box;
padding: 22px 12px 31px 13px;
border-radius: 3px;
border: solid 1px #eee;
background-color: #fff;
}
.user-wrapper-outer .wrapper-contents {
  flex: 0 1 calc(100% - 42px);
}
.rate-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 15px 22px;
  box-sizing: border-box;
}
.game-wrap {border: 1px solid #d5d5d5;box-sizing: border-box;}
.rate-item-wrap {
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  flex: 0 1 23%;
}
.rate-item-wrap .item-title {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  width: 78px;
  flex: 1 0 auto;
  word-break: keep-all;
  color: #d93832;
  /* white-space: nowrap; */
}
.rate-item-container .item-title > span {
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.rate-item-container .item-line {
  width: 1px;
  background: #c5c5c5;
  height: 25px;
}
.rate-item-container .info-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
  padding: 5px 8px;
  font-weight: 700;
  width: 60px;
  font-size: 1em;
  margin-right: 0;
  border: 1px solid #e1e0e0;
  user-select: none;
}

.rate-item-container .item-line:last-child {
  display: none;
}
.batchRate {
  width: 70px;
  text-align: center;
}
.control-wrap {
  display: flex;
  clear: unset;
  overflow: unset;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0 20px;
}
</style>
